@import "../sass/vars.scss";

.modal-header{
  border-bottom: none !important;
}

.modal-backdrop {
  background-color: $greenBackground !important;
  opacity: 0.94 !important;
}

.modal-title{
  font-family: $font-barlow-Medium;
  font-style: normal;
  font-weight: 500 !important;
  color: $blackText;
}

.btn-green, .btn-green-loading{
  font-family: $font-barlow-Medium;
  color: $whiteText !important;
  border-color: $greenMain !important;
  border-radius: 36px !important;
  width: 15.5em;
  height: 3em;
}

.btn-green{
  background-color: $greenMain !important;
}

.btn-green-loading{
  background-color: $greenLoading !important;
}


.btn-green:hover{
  color: #358E81 !important;
  border-color: white !important;
  background-color: white !important;
}

.btn-white {
  font-family: $font-barlow-Medium;
  color: $greenMain  !important;
  background-color: $whiteText  !important;
  border-color:  $whiteText !important;
  border-radius: 36px !important;
  width: 15.5em;
  height: 3em;
}

.btn-white:hover{
  color:$whiteText !important;
  border-color: $greenMain !important;
  background-color: $greenMain !important;
}

.input-form{
  font-family: $font-barlow;
  color: $colorTextInput !important;
  background: $inputForm;
  border-radius: $borderRadiusInput !important;
  font-size: $texts !important;
  border: 1px solid $whiteText !important;
}

.checkbox-form{
  background: $whiteText;
  border: 1px solid $links;
  vertical-align: middle;
  border-radius: 5px;
}

.links{
  color: $links !important;
  cursor: pointer;
}

.link-forget{
  font-family: $font-roboto;
  font-size: $texts;
}

.text-terms{
  font-family: $font-barlow;
  color: $grayText;
  font-style: normal;
  font-weight: normal;
  font-size: $textsTerm;
  line-height: 25px;
}

.text-account{
  font-family: $font-barlow;
  color: $grayText;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
}

.close{
  font-weight: normal !important;
}

.text-download-pdf{
  font-family: $font-roboto;
  font-size: $texts;
  color: $grayText;
}

.input-right{
  padding-left: 10px !important;
  padding-right: 20px !important;
}

.input-left{
  padding-left: 20px !important;
  padding-right: 10px !important;
}

.modal-dialog.modal-sm {
  margin-top: $spaceMedium;
}

.modal-header button span {
  font-size: 45px;
  font-family: $font-barlow;
  line-height: 20px;
  font-weight: 100;
}

.select-label{
  font-family: $font-roboto;
  color: $grayText;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
}

.color-danger { color: $mainRed}
.color-success { color: $greenLigth}

.text-email{
  font-family: $font-barlow;
  color: $grayText;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
}

.code-email{
  color: $black
}

.space-top{
  padding-top: .7rem!important;
}

.texts-alerts{
  font-size: 15px;
  font-family: $font-barlow-Medium;
}

@media screen and (max-width: 770px) {
  .input-right{
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .input-left{
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

}
