@import "../../core/sass/vars.scss";

.modal-blog > div  {
  max-width: none !important;
  margin: 0 !important;
}

.text-how{
  font-family: $font-barlow-Medium !important;
  font-style: normal;
  font-weight: 500;
  font-size: 70px;
  line-height: 75px;
  text-align: center;
  color:$textHow;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.text-how:hover{
  color:$textHow;
}

.modal-how{
  background-color: $greenBackground !important;
}

.cursor-style:hover{
  cursor: pointer !important;
}

.menu-img{
  padding-top: 4%
}

@media screen and (max-width: 770px) {
  .text-how {
    font-size: 40px;
    line-height: 45px;
  }
}
