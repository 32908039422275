@import "../../core/sass/vars.scss";

.background-left-dash{
  background-color: $backChoose !important;
}
.background-right-dash{
  background-color: $backRightChoose !important;
}

.dashboard-item-dash{
  font-family: $font-barlow;
  font-style: normal;
  font-weight: normal;
  color: $grayText;
  font-size: 23px;
  line-height: 40px;
}

.dashboard-item-dash:hover{
  color: $blackText;

}

.separator-dash{
  font-family: $font-barlow;
  margin-bottom: 10px !important;
  border-bottom: 1px solid $weekBorder;
}

.salute-dash{
  font-family: $font-barlow-Medium;
  font-size: 25px;
  color: $blackText;

}

.copyright-dash{
  font-family: $font-roboto-light;
  color: $grayText;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}

.history-desc-dash{
  font-family: $font-roboto;
  color: $grayText;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
}

.history-dash{
  font-family: $font-barlow-Medium;
  font-size: 18px;
  color: $blackText;
}

.btn-action-dash{
  background-color: inherit;
  color: inherit;
  border: none;
}

.btn-action-dash:focus{
  outline: none;
}

.focus-item-dash:active{
  background-color: $backStepAmount !important;
}

.link-key-dash{
  color: $blackText !important;
  font-family: $font-barlow;
}

.link-key-dash:active{
  color: $whiteText !important;
}

.active-dash{
  color: $blackText !important;
}

.cursor-dash{
  cursor: pointer;
}

button img.image-dow:last-child {
  display: none;
}
button:hover img.image-dow:last-child {
  display: inline-block;
}
button:hover img.image-dow:first-child {
  display: none;
}

.pay {
  font-family: $font-roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
}

.inactive-pay {
  color: $mainRed !important;
}

.active-pay {
  color: $greenMain !important;
}
