/* colors */
$yellowMain: #F5CC4B;
$brownMain: #7C3D46;
$yellowLigth: #F3EE8D;
$orange: #FDDD78;
$greenMain: #358E81;
$greenLigth: #63C7B9;
$gray: #FFFFFF;
$black: #000;
$mainRed: #E65282;
$trasparent: #00000000;
$greenBackground: #03221E;
$grayBackground: #F0ECE3;
$blackText: #2F2F2F;
$inputForm: #FAF9F6;
$links: #52B0A3;
$whiteText: #FFFFFF;
$colorTextInput: #7E7D79;
$grayText: #757575;
$textHow: #B1CAC5;
$backPink: #F4B2B6;
$textBack: #FCE8E9;
$textBackFeed: #FAC9D5;
$textBackAmount: #CAE7E3;
$textBackPlan: #ACFEF8;
$inputBottom: #C0A243;
$textInputBottom: #735905;
$backRosa: #ED4B72;
$textStepFedding: #FFC4F2;
$backStepAmount: #4EB0A3;
$textStepAmount: #CAEFE4;
$backPlan: #7ACCC6;
$textPlan: #D0FFFB;
$buttonPlan: #83C8BF;
$buttonPlanMiddle: #9FE7DD;
$backChoose: #E9E1D0;
$backRightChoose: #F0ECE3;
$weekBorder: #DACFB7;
$textDisiable: #D1CDC4;
$checkDisiable: #CEC5B0;
$planPay: #F4F0E8;
$black: #010000;
$greenLoading: #0F463E;
/* fonts */
$font-barlow: 'BarlowSemiCondensed-Regular';
$font-barlow-bold: 'BarlowSemiCondensed-Bold';
$font-barlow-Light: 'BarlowSemiCondensed-Light';
$font-barlow-Medium: 'BarlowSemiCondensed-Medium';
$font-barlow-SemiBold: 'BarlowSemiCondensed-SemiBold';
$font-roboto: 'RobotoCondensed-Regular';
$font-roboto-bold: 'RobotoCondensed-Bold';
$font-crimson-italic: 'CrimsonText-Italic';
$font-crimson-regular: 'CrimsonText-Regular';
$font-roboto-light: 'RobotoCondensed-Light';
/* fonts size*/
$texts: 11px;
$title: 22px;
$textsTerm: 10px;

/* spaces */
$spaceMedium: 60px;
$spaceMain: 20px;

/* size */
$size: 100px;

$borderRadiusInput: 5px;

a:hover{
  text-decoration: none !important;
}

button img.image:last-child {
  display: none;
}
button:hover img.image:last-child {
  display: inline-block;
}
button:hover img.image:first-child {
  display: none;
}

.height-view{
  height: 100vh;
}

.Toastify__toast-body {
    line-height: 20px;
}

.key-account {
  height: 2.7em;
}

.key-account-gray {
  height: 2.1em;
}
