@import "../../core/sass/vars.scss";

.background-left-plan{
  background-color: $backPlan !important;
}
.background-right-plan{
  background-color: $backStepAmount !important;
}

.text-back-plan{
  font-family: $font-barlow-Medium;
  color: $textBackPlan;
  text-decoration: none !important;
}

.text-back-plan:hover{
  color: $textBackPlan;
  cursor: pointer !important;
}

.text-init-plan{
  font-family: $font-barlow-Medium;
  color: $whiteText;
  letter-spacing: -0.01em;
  font-size: 30px;
}

.text-subinit-plan{
  font-family: $font-barlow-Medium;
  color: $textPlan;
  font-size: 70px;
  line-height: 70px;
  letter-spacing: -0.01em;
  font-weight: 500;
}

.btn-celeste-steps-plan {
  width: 28em;
  height: 4.5em;
  background: $buttonPlan;
  border: none !important
}

.btn-celeste-steps-plan:hover, .btn-celeste-steps-plan.active{
  background: $buttonPlanMiddle;
}

.avocado {
  top: 48%;
  right: 15%;
}

.number-month-plan, .value-plan-plan{
  font-family: $font-barlow-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  color: $blackText;
}

.month-plan{
  font-family: $font-barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  color: $blackText;
}

.vl-plan{
  border-left: 1px solid green;
    height: 100px;
    position: absolute;
}

.slah-mes{
  font-family: $font-barlow;
  font-family: $font-barlow;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.space-plan{
  margin-top: 6rem;
}

.btn-celeste-steps-plan span.react-loading-skeleton {
    background-color: #83C8BF;
}

@media screen and (max-width: 470px) {
  .text-subinit-plan{
    font-size: 45px;
    line-height: 50px;
  }


}

@media screen and (max-width: 400px) {

  .btn-celeste-steps-plan {
    width: 20em;
  }

  .number-month-plan, .value-plan-plan,.month-plan{
    font-size: 20px;
  }
}
