@import "../../core/sass/vars.scss";

.background-left-menu{
  background-color: $backChoose !important;
}
.background-right-menu{
  background-color: $backRightChoose !important;
}

.text-back-menu{
  font-family: $font-barlow-Medium;
  color: $black;
  text-decoration: none !important;
}
.text-back-menu:hover{
  color: $black;
  cursor: pointer !important;
}

.text-init-menu{
  font-family: $font-barlow-Medium;
  color: $blackText;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  font-style: normal;

}

.text-subinit-menu{
  font-family: $font-barlow;
  color: $blackText;
  font-size: 20px;
  line-height: 26px;
  font-weight: normal;
  font-style: normal;
}

.text-cab-menu{
  font-family: $font-barlow-Medium;
  color: $grayText;
}

.dropdown-color-menu{
  background-color: $trasparent !important;
  border-color: $trasparent  !important;
  box-shadow: none !important;
}

.dropdown-toggle-menu::after{
  content: none !important;
}

.dropdown-background-menu, .modal-content-menu{
  background-color: $grayBackground !important;
  border-radius: 0 !important;
}

.dropdown-background-menu{
  color: $blackText !important;
  font-family: $font-barlow;
}

.name-menu-menu{
  font-family: $font-barlow-Medium;
  color: $blackText;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.description-menu-menu{
  font-family: $font-roboto-light;
  color: $grayText;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
}

.week-menu{
  font-family: $font-barlow;
  margin-bottom: 10px !important;
  border-bottom: 1px solid $weekBorder;
}

.color-link {
  text-decoration: underline;
  cursor: pointer;
}

.active-week{
  color: $brownMain;
  text-decoration: underline;
  cursor: pointer;
}

.week-disable {
  color: $textDisiable;
}

.fill-week-1 , .color-link{
  color: $backStepAmount;
  cursor: pointer;
}

.fill-week-2 {
  color: $backRosa;
  cursor: pointer;
}

.fill-week-3{
  color: $yellowMain;
  cursor: pointer;
}

.fill-week-4 {
  color: $brownMain;
  cursor: pointer;
}

.size-icon-menu{
  font-size: 1.5em !important;
}

.name-almorza-menu{
  font-family: $font-barlow-Medium;
  font-size: 16px;
}

.name-input-menu{
  width: 14em;
  height: 2.3em;
  border-radius: 5px;
  border: solid 1px white;
  font-family: $font-barlow;
  color: $colorTextInput !important;
}

.name-input-menu:focus{
  border: solid 1px white;
}

.menu::placeholder{
  color: $colorTextInput;
}

.copyright-menu{
  font-family: $font-roboto-light;
  color: $grayText;
  font-size: 12px;
}

.btn-green-steps-menu {
  font-family: $font-barlow-Medium;
  color: $whiteText !important;
  background-color: $greenMain !important;
  border-color: $greenMain !important;
  border-radius: 36px !important;
  width: 8em;
  height: 3em;
}

.btn-green-steps-menu:hover{
  color: #358E81 !important;
  border-color: white !important;
  background-color: white !important;
}

.focus-item:active{
  background-color: $backStepAmount !important;
}

.image_picker {
  width: 100% !important;

  .responsive {
    position: relative;
    float: left;
    opacity: 1;

    .thumbnail {
      width: 100% !important; /* Set a mdall width */
      height: 100% !important;
      opacity: 1;
      margin: 0;
    }

    .selected {
      background-color: rgba(0, 0, 0, 0.25);
    }

    &:hover {
      cursor: pointer;
    }

    .checked {
      display: none;
    }
  }

  .selected {

    .checked {
      display: inline-block;
      transition: .5s ease;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }


  .clear {
    clear: both;
  }
}

// Notifications
.Toastify__toast--info {
  background-color: $whiteText;
  color: $backStepAmount;
}

.Toastify__toast--success {
    background: $backStepAmount;
}

.Toastify__toast--info .Toastify__close-button {
  color: $backStepAmount;
}

.Toastify__toast {
  border-radius: 6px;
}

.error-description, {
  color: $mainRed;
  font-family: $font-barlow-Medium;
}
