@import "../sass/vars.scss";

.dropdown-color{
  background-color: $trasparent !important;
  border-color: $trasparent  !important;
  box-shadow: none !important;
}

.dropdown-toggle::after{
  content: none !important;
}

.dropdown-background, .modal-content{
  background-color: $grayBackground !important;
  border-radius: 0 !important;
}

.dropdown-background{
  color: $blackText !important;
  font-family: $font-barlow;
}

.navbar-top{
  display: flex !important;
}

.focus-item-nav:active{
  background-color: $backStepAmount !important;
}



.btn-action-nav{
  background-color: inherit;
  color: inherit;
  border: none;
}

.link-key{
  color: $blackText !important;
  font-family: $font-barlow;
}

.link-key:active{
  color: $whiteText !important;
}

.btn-action-nav:focus{
  outline: none;
}
