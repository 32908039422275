@import "../../core/sass/vars.scss";

.background-left-choose{
  background-color: $backChoose !important;
}
.background-right-choose{
  background-color: $backRightChoose !important;
}

.text-init-choose{
  font-family: $font-barlow-Medium;
  color: $blackText;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;

}

.sub-total-choose, .total-choose{
  font-family: $font-barlow-Medium;
  color: $blackText;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 40px;
}

.check-disable-choose, .text-disable-choose{
  color: $checkDisiable;

}

.check-enable-choose, .text-enable-choose, .sub-plan-choose{
  color: #000000;
}

.text-enable-choose, .text-disable-choose{
  font-family: $font-barlow;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 23px;
}


.description-menu-choose{
  font-family: $font-roboto-light;
  color: $grayText;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.size-icon-choose{
  font-size: 1.5em !important;
}

.name-input-choose{
  width: 14em;
  height: 2.3em;
  border-radius: 5px;
  border: solid 1px white;
  font-family: $font-barlow;
  color: $colorTextInput !important;
}


.number-month-choose{
  font-family: $font-barlow-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 40px;
}

.background-plan-choose{
  background-color: $planPay;
}

.month-choose, .value-plan-choose{
  font-family: $font-barlow;
  font-style: normal;
  font-size: 23px;
  line-height: 40px;
  color: $blackText;
  font-weight: 400;

}

.price-choose{
  font-family: $font-barlow;
  margin-bottom: 10px !important;
  border-bottom: 1px solid $weekBorder;
}

.my-plan-choose{
  font-family: $font-barlow-Medium;
  font-size: 25px;
  color: $blackText;

}

.sub-plan-choose{
  font-family: $font-barlow-Medium;
  font-size: 17px;
}

.copyright-choose{
  font-family: $font-roboto-light;
  color: $grayText;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}

.height-button-choose{
  height: 3.5em;
}

.btn-green-steps-choose {
  font-family: $font-barlow-Medium;
  color: $whiteText !important;
  background-color: $greenMain !important;
  border-color: $greenMain !important;
  border-radius: 36px !important;
  width: 8em;
  height: 3em;
}

.btn-green-steps-choose:hover{
  color: #358E81 !important;
  border-color: white !important;
  background-color: white !important;
}


@media screen and (max-width: 470px) {
  .img-resp-choose{
    width: 100%;
  }

  .text-enable-choose, .text-disable-choose{
    line-height: 14px;

  }
}
