@import "../../core/sass/vars.scss";

.background-left-buyer{
  background-color: $backChoose !important;
}
.background-right-buyer{
  background-color: $backRightChoose !important;
}

.text-init-buyer{
  font-family: $font-barlow-Medium;
  color: $blackText;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;

}

.sub-total-buyer, .total-buyer{
  font-family: $font-barlow-Medium;
  color: $blackText;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 40px;
}

.check-disable-buyer, .text-disable-buyer{
  color: $checkDisiable;

}

.check-enable-buyer, .text-enable-buyer, .sub-plan-buyer{
  color: #000000;
}

.text-enable-buyer, .text-disable-buyer{
  font-family: $font-barlow;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 23px;
}


.description-menu-buyer{
  font-family: $font-roboto-light;
  color: $grayText;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
}

.select-label-buyer{
  font-family: $font-roboto;
  color: $grayText;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
}

.size-icon-buyer{
  font-size: 1.5em !important;
}

.number-month-buyer {
  font-family: $font-barlow-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 40px;
}

.background-plan-buyer{
  background-color: $planPay;
}

.month-buyer, .value-plan-buyer{
  font-family: $font-barlow;
  font-style: normal;
  font-size: 23px;
  line-height: 40px;
  color: $blackText;
  font-weight: 400;

}

.price-buyer{
  font-family: $font-barlow;
  margin-bottom: 10px !important;
  border-bottom: 1px solid $weekBorder;
}

.my-plan-buyer{
  font-family: $font-barlow-Medium;
  font-size: 25px;
  color: $blackText;

}

.copyright-buyer{
  font-family: $font-roboto-light;
  color: $grayText;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}

.height-button-buyer{
  height: 3.5em;
}

.top-line-buyer{
  margin-top: 1.8rem!important;
}

.input-h-buyer{
  height: 3.2em !important;

}

.btn-green-steps-buyer{
  font-family: $font-barlow-Medium;
  color: $whiteText !important;
  background-color: $greenMain !important;
  border-color: $greenMain !important;
  border-radius: 36px !important;
  width: 8em;
  height: 3em;
}

.btn-green-steps-buyer:hover{
  color: #358E81 !important;
  border-color: white !important;
  background-color: white !important;
}


@media screen and (max-width: 470px) {
  .text-enable-buyer, .text-disable-buyer{
    line-height: 14px;

  }
}
