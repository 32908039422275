@font-face {
  font-family: 'BarlowSemiCondensed-Bold';
  font-weight: normal;
  font-style: normal;
  src: url('core/fonts-mw/BarlowSemiCondensed-Bold/BarlowSemiCondensed-Bold.eot?#iefix') format('embedded-opentype'),
	     url('core/fonts-mw/BarlowSemiCondensed-Bold/BarlowSemiCondensed-Bold.woff') format('woff'),
       url('core/fonts-mw/BarlowSemiCondensed-Bold/BarlowSemiCondensed-Bold.ttf')  format('truetype'),
       url('core/fonts-mw/BarlowSemiCondensed-Bold/BarlowSemiCondensed-Bold.svg#BarlowSemiCondensed-Bold') format('svg');
}

@font-face {
  font-family: 'BarlowSemiCondensed-Regular';
  font-weight: normal;
  font-style: normal;
  src: url('core/fonts-mw/BarlowSemiCondensed-Regular/BarlowSemiCondensed-Regular.eot?#iefix') format('embedded-opentype'),
	     url('core/fonts-mw/BarlowSemiCondensed-Regular/BarlowSemiCondensed-Regular.woff') format('woff'),
       url('core/fonts-mw/BarlowSemiCondensed-Regular/BarlowSemiCondensed-Regular.ttf')  format('truetype'),
       url('core/fonts-mw/BarlowSemiCondensed-Regular/BarlowSemiCondensed-Regular.svg#BarlowSemiCondensed-Regular') format('svg');
}

@font-face {
  font-family: 'BarlowSemiCondensed-Medium';
  font-weight: normal;
  font-style: normal;
  src: url('core/fonts-mw/BarlowSemiCondensed-Medium/BarlowSemiCondensed-Medium.eot?#iefix') format('embedded-opentype'),
	     url('core/fonts-mw/BarlowSemiCondensed-Medium/BarlowSemiCondensed-Medium.woff') format('woff'),
       url('core/fonts-mw/BarlowSemiCondensed-Medium/BarlowSemiCondensed-Medium.ttf')  format('truetype'),
       url('core/fonts-mw/BarlowSemiCondensed-Medium/BarlowSemiCondensed-Medium.svg#BarlowSemiCondensed-Medium') format('svg');
}

@font-face {
  font-family: 'BarlowSemiCondensed-Light';
  font-weight: normal;
  font-style: normal;
  src: url('core/fonts-mw/BarlowSemiCondensed-Light/BarlowSemiCondensed-Light.eot?#iefix') format('embedded-opentype'),
	     url('core/fonts-mw/BarlowSemiCondensed-Light/BarlowSemiCondensed-Light.woff') format('woff'),
       url('core/fonts-mw/BarlowSemiCondensed-Light/BarlowSemiCondensed-Light.ttf')  format('truetype'),
       url('core/fonts-mw/BarlowSemiCondensed-Light/BarlowSemiCondensed-Light.svg#BarlowSemiCondensed-Light') format('svg');
}

@font-face {
  font-family: 'BarlowSemiCondensed-SemiBold';
  font-weight: normal;
  font-style: normal;
  src: url('core/fonts-mw/BarlowSemiCondensed-SemiBold/BarlowSemiCondensed-SemiBold.eot?#iefix') format('embedded-opentype'),
	     url('core/fonts-mw/BarlowSemiCondensed-SemiBold/BarlowSemiCondensed-SemiBold.woff') format('woff'),
       url('core/fonts-mw/BarlowSemiCondensed-SemiBold/BarlowSemiCondensed-SemiBold.ttf')  format('truetype'),
       url('core/fonts-mw/BarlowSemiCondensed-SemiBold/BarlowSemiCondensed-SemiBold.svg#BarlowSemiCondensed-SemiBold') format('svg');
}

@font-face {
  font-family: 'RobotoCondensed-Bold';
  font-weight: normal;
  font-style: normal;
  src: url('core/fonts-mw/RobotoCondensed-Bold/RobotoCondensed-Bold.eot?#iefix') format('embedded-opentype'),
	     url('core/fonts-mw/RobotoCondensed-Bold/RobotoCondensed-Bold.woff') format('woff'),
       url('core/fonts-mw/RobotoCondensed-Bold/RobotoCondensed-Bold.ttf')  format('truetype'),
       url('core/fonts-mw/RobotoCondensed-Bold/RobotoCondensed-Bold.svg#RobotoCondensed-Bold') format('svg');
}

@font-face {
  font-family: 'RobotoCondensed-Regular';
  font-weight: normal;
  font-style: normal;
  src: url('core/fonts-mw/RobotoCondensed-Regular/RobotoCondensed-Regular.eot?#iefix') format('embedded-opentype'),
	     url('core/fonts-mw/RobotoCondensed-Regular/RobotoCondensed-Regular.woff') format('woff'),
       url('core/fonts-mw/RobotoCondensed-Regular/RobotoCondensed-Regular.ttf')  format('truetype'),
       url('core/fonts-mw/RobotoCondensed-Regular/RobotoCondensed-Regular.svg#RobotoCondensed-Regular') format('svg');
}

@font-face {
  font-family: 'RobotoCondensed-Light';
  font-weight: normal;
  font-style: normal;
  src: url('core/fonts-mw/RobotoCondensed-Light/RobotoCondensed-Light.eot?#iefix') format('embedded-opentype'),
	     url('core/fonts-mw/RobotoCondensed-Light/RobotoCondensed-Light.woff') format('woff'),
       url('core/fonts-mw/RobotoCondensed-Light/RobotoCondensed-Light.ttf')  format('truetype'),
       url('core/fonts-mw/RobotoCondensed-Light/RobotoCondensed-Light.svg#RobotoCondensed-Light') format('svg');
}

@font-face {
  font-family: 'CrimsonText-Italic';
  font-weight: normal;
  font-style: normal;
  src: url('core/fonts-mw/CrimsonText-Italic/CrimsonText-Italic.eot?#iefix') format('embedded-opentype'),
	     url('core/fonts-mw/CrimsonText-Italic/CrimsonText-Italic.woff') format('woff'),
       url('core/fonts-mw/CrimsonText-Italic/CrimsonText-Italic.ttf')  format('truetype'),
       url('core/fonts-mw/CrimsonText-Italic/CrimsonText-Italic.svg#CrimsonText-Italic') format('svg');
}

@font-face {
  font-family: 'CrimsonText-Regular';
  font-weight: normal;
  font-style: normal;
  src: url('core/fonts-mw/CrimsonText-Regular/CrimsonText-Regular.eot?#iefix') format('embedded-opentype'),
	     url('core/fonts-mw/CrimsonText-Regular/CrimsonText-Regular.woff') format('woff'),
       url('core/fonts-mw/CrimsonText-Regular/CrimsonText-Regular.ttf')  format('truetype'),
       url('core/fonts-mw/CrimsonText-Regular/CrimsonText-Regular.svg#CrimsonText-Regular') format('svg');
}
