@import "../../core/sass/vars.scss";

.background-left-conf{
  background-color: $backChoose !important;
}
.background-right-conf{
  background-color: $backRightChoose !important;
}

.text-init-conf{
  font-family: $font-barlow-Medium;
  color: $blackText;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;

}

.sub-total-conf, .total-conf{
  font-family: $font-barlow-Medium;
  color: $blackText;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 40px;
}

.check-disable-conf, .text-disable-conf{
  color: $checkDisiable;

}

.check-enable-conf, .text-enable-conf, .sub-plan-conf, .text-email-conf{
  color: #000000;
}

.text-enable-conf, .text-disable-conf{
  font-family: $font-barlow;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 23px;
}

.size-icon-conf{
  font-size: 1.5em !important;
}

.number-month-conf {
  font-family: $font-barlow-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 40px;
}

.background-plan-conf{
  background-color: $planPay;
}

.month-conf, .value-plan-conf{
  font-family: $font-barlow;
  font-style: normal;
  font-size: 23px;
  line-height: 40px;
  color: $blackText;
  font-weight: 400;

}

.price-conf{
  font-family: $font-barlow;
  margin-bottom: 10px !important;
  border-bottom: 1px solid $weekBorder;
}

.my-plan-conf{
  font-family: $font-barlow-Medium;
  font-size: 25px;
  color: $blackText;

}

.copyright-conf{
  font-family: $font-roboto-light;
  color: $grayText;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}

.height-button-conf{
  height: 3.5em;
}

.background-confirmed{
  background-color: $whiteText;
  height: 3.5em;
}


.order-conf{
  font-family: $font-roboto;
  font-size: 18px;
  color: $grayText;
}

.space-conf{
  margin-top: 0.8rem!important;
}

.history-conf{
  font-family: $font-barlow-Medium;
  font-size: 18px;
  color: $blackText;
}

.history-desc-conf{
  font-family: $font-roboto;
  color: $grayText;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
}

.btn-green-steps-buyer img.arrow {
    padding-top: 5%;
    margin-left: 3px;
}

@media screen and (max-width: 470px) {
  .background-confirmed{
    height: 6.5em;
  }
  .text-enable-conf, .text-disable-conf{
       line-height: 14px;
  }

}
