@import "../../core/sass/vars.scss";

.background-left-amount{
  background-color: $backStepAmount !important;
}
.background-right-amount{
  background-color: $orange !important;
}

.text-back-amount{
  font-family: $font-barlow-Medium;
  color: $textBackAmount;
  text-decoration: none !important;
}
.text-back-amount:hover{
  color: $textBackAmount;
  cursor: pointer !important;
}

.text-subinit-amount{
  font-family: $font-barlow-Medium;
  color: $textStepAmount;
  font-size: 70px;
  line-height: 70px;
  letter-spacing: -0.01em;
  font-weight: 500;
  font-style: normal;
}

.borde-amount{
  border: none !important;
  border-bottom: 1px solid $inputBottom !important;
  background-color: $trasparent !important;
  font-family: $font-crimson-italic;
  color: $textInputBottom;
  width: 5.2em;
  font-size: 40px;
  line-height: normal !important;
}


.borde-amount:focus{
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.btn-green-steps-amount {
  font-family: $font-barlow-Medium;
  color: $whiteText !important;
  background-color: $greenMain !important;
  border-color: $greenMain !important;
  border-radius: 36px !important;
  width: 8em;
  height: 3em;
}

.btn-green-steps-amount:hover{
  color: #358E81 !important;
  border-color: white !important;
  background-color: white !important;
}

.control-label-amount{
  color: $inputBottom;
  font-family: $font-barlow-Light;
  display: block;
}

.amount::placeholder{
  color: $inputBottom;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 770px) {

  .img-resp-amount{
    width: 100%;
  }
}

@media screen and (max-width: 470px) {
  .text-subinit-amount{
    font-size: 45px;
    line-height: 50px;
  }
}
