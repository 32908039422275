@import "../../core/sass/vars.scss";

.background-left-name{
  background-color: $backPink;
}
.background-right-name{
  background-color: $orange !important;
}

.text-back-name{
  font-family: $font-barlow-Medium;
  color: $textBack;
  text-decoration: none !important;
}
.text-back-name:hover{
  color: $textBack;
  cursor: pointer !important;
}

.text-init-name{
  font-family: $font-barlow-Medium;
  color: $whiteText;
  letter-spacing: -0.01em;
  font-size: 30px;
}

.text-subinit-name{
  font-family: $font-barlow-Medium;
  color: $mainRed;
  font-size: 70px;
  line-height: 70px;
  letter-spacing: -0.01em;
  font-weight: 500;
}

.borde-name{
  border: none !important;
  border-bottom: 1px solid $inputBottom !important;
  background-color: $trasparent !important;
  font-family: $font-crimson-italic;
  color: $textInputBottom;
  width: 5.2em;
  font-size: 40px;
  line-height: normal !important;
}

.borde-name:focus{
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.btn-green-steps-name {
  font-family: $font-barlow-Medium;
  color: $whiteText !important;
  background-color: $greenMain !important;
  border-color: $greenMain !important;
  border-radius: 36px !important;
  width: 8em;
  height: 3em;

}

.btn-green-steps-name:hover{
  color: $greenMain !important;
  border-color: white !important;
  background-color: white !important;
}

.control-label-name{
  color: $inputBottom;
  font-family: $font-barlow-Light;
  display: block;
}

.name::placeholder{
  color: $inputBottom;
}

@media screen and (max-width: 770px) {

  .img-resp{
    width: 100%;
  }
}

@media screen and (max-width: 470px) {
  .text-subinit-name{
    font-size: 45px;
    line-height: 50px;
  }
}
