@import "../../core/sass/vars.scss";

.image_picker {
  width: 100%;

  .responsive {
    position: relative;
    float: left;
    opacity: 1;

    .thumbnail {
      border: 1px solid #ddd; /* Gray border */
      border-radius: 4px;  /* Rounded border */
      width: 150px; /* Set a small width */
      opacity: 1;
      margin: 0;

      &:hover {
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.7);
      }
    }

    .selected {
      background-color: rgba(0, 140, 186, 1);
    }

    &:hover {
      cursor: pointer;
    }

    .checked {
      display: none;
    }
  }

  .selected {
    opacity: 0.7;

    .checked {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;

      .color-week-1 {
        content: url('/../check-green.png');
      }

      .color-week-2 {
        content: url('/../check-rosa.png');
      }
      .color-week-3 {
        content: url('/../check-yellow.png');
      }
      .color-week-4 {
        content: url('/../check-brown.png');

      }
    }
  }

  .clear {
    clear: both;
  }
}
