@import "../../core/sass/vars.scss";

.background-left-profile{
  background-color: $backChoose !important;
}
.background-right-profile{
  background-color: $backRightChoose !important;
}

.dashboard-item-profile{
  font-family: $font-barlow;
  color: $grayText;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 40px;
}

.dashboard-item-profile:hover{
  color: $blackText;
  text-decoration: none !important;
}

.select-label-profile{
  font-family: $font-roboto;
  color: $grayText;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
}


.separator-profile{
  font-family: $font-barlow;
  margin-bottom: 10px !important;
  border-bottom: 1px solid $weekBorder;
}

.salute-profile{
  font-family: $font-barlow-Medium;
  font-size: 25px;
  color: $blackText;

}

.copyright-profile{
  font-family: $font-roboto-light;
  color: $grayText;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
}


.btn-green-steps-profile{
  font-family: $font-barlow-Medium;
  color: $whiteText !important;
  background-color: $greenMain !important;
  border-color: $greenMain !important;
  border-radius: 36px !important;
  width: 15em;
  height: 3em;
  font-size: 16px !important;
}

.btn-green-steps-profile:hover{
  color: #358E81 !important;
  border-color: white !important;
  background-color: white !important;
}

.icon-profile {
  font-size: 1em !important;
  color: $colorTextInput !important;

}

.edit-text-profile{
  font-size: 13px !important;
  color: $colorTextInput !important;
  font-family: $font-crimson-italic;

}

.input-h-profile{
  height: 3.2em !important;
}

.focus-item-pro:active{
  background-color: $backStepAmount !important;
}

.btn-action-pro{
  background-color: inherit;
  color: inherit;
  border: none;
}

.btn-action-pro:focus{
  outline: none;
}

.link-key-pro{
  color: $blackText !important;
  font-family: $font-barlow;
}

.link-key-pro:active{
  color: $whiteText !important;
}

.active-pro{
  color: $blackText !important;
}

.cursor-profile{
  cursor: pointer;
}
