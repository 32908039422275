@import "../../core/sass/vars.scss";

.background-left-feeding{
  background-color: $backRosa !important;
}
.background-right-feeding{
  background-color: $greenLigth !important;
}

.text-back-feeding{
  font-family: $font-barlow-Medium;
  color: $textBackFeed;
  text-decoration: none !important;
}

.text-back-feeding:hover{
  color: $textBackFeed;
  cursor: pointer !important;
}

.img-button-feeding:hover{
  cursor: pointer !important;
}

.text-init-feeding{
  font-family: $font-barlow-Medium;
  color: $whiteText;
  letter-spacing: -0.01em;
  font-size: 30px;
}

.text-subinit-feeding{
  font-family: $font-barlow-Medium;
  color: $textStepFedding;
  font-size: 70px;
  line-height: 70px;
  letter-spacing: -0.01em;
  font-weight: 500;
  font-style: normal;
}


@media screen and (max-width: 770px) {

  .img-resp-feeding{
    width: 100%;
  }
}

@media screen and (max-width: 470px) {
  .text-subinit-feeding{
    font-size: 45px;
    line-height: 50px;
  }
}
